import React, { useContext } from 'react';
import { get, range } from 'lodash';
import { Formik, Form  } from 'formik';
import uuid from 'uuid/v4';
import moment from 'moment';
import differenceInYears from 'date-fns/difference_in_years';
import { navigate } from '@reach/router';
import { Container } from 'ui/containers';
import {
  SplitContainer,
  LeftContainer,
  RightContainer,
} from 'ui';

import HotelBookedSummary from 'containers/Booking/HotelBookedSummary';
import BookingContext from 'contexts/booking';
import { useGTM } from '@tymate/react-gtm';
import FormRoomsComposition from './FormRoomsComposition'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ErrorMessage = styled.h3`
  color:red;
`;

const BookingRoomsComposition = () => {
  const { hotel, startDate, endDate, searchQuery, onKoediaSearch, ageCategories, koediaSearch } = useContext(
    BookingContext,
  );
  const { pushDataLayer } = useGTM();

  const handleSubmit = async values => {
    onKoediaSearch({
      koediaSearch: {
        rooms: values.rooms.map(room => ({
          people: [
            ...range(0, room.adults).map(() => ({
              id: uuid(),
              type: 'adult',
            })),
            ...range(0, room.children).map(index => {
              const {birthday} = room.childrenAge[index];
              const birthdate = moment(birthday).toDate();
              return {
                id: uuid(),
                type:
                  differenceInYears(startDate, birthdate) <= 2
                    ? 'infant'
                    : 'child',
                birthdate
              };
            }),
            ...range(0, room.infants).map(index => {
              return {
                id: uuid(),
                type:'infant'
              };
            }),
          ],
        })),
      },
    });
    pushDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 2,
          },
        },
      },
    });
    navigate(`/booking/${hotel.id}/rooms?${searchQuery}`);
  };

  const { t } = useTranslation();

  let errorMessage;

  const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    values.rooms.forEach(room => {
      if (room.children> 0 && (room.childrenAge=== undefined || room.childrenAge.length!== room.children)){
        errors.birthdayMandatory = t('hotel.room.birthdayMandatory');
        errorMessage = t('hotel.room.birthdayMandatory');
      }
    });
    return errors;
  };

  let roomsCompositions = {}
	if (koediaSearch){
		roomsCompositions.rooms = koediaSearch.rooms.map((room) => {
		  return {
		    adults: room.people.filter((people) => people.type==='adult').length,
        children: room.people.filter((people) => people.type==='child').length ,
        infants: room.people.filter((people) => people.type==='infant').length,
        childrenAge: room.people.filter((people) => people.type==='child').map((child) => {
          return {
            birthday: moment(child.birthdate).format()
          }
        })
		  }
    });
	}else{
    const adults = ageCategories && ageCategories.adults? Number(ageCategories.adults): 2;
    const children = ageCategories && ageCategories.children? Number(ageCategories.children): 0;
    const infants = ageCategories && ageCategories.infants? Number(ageCategories.infants): 0;
		roomsCompositions = { rooms: [{ adults: adults, children: children ,infants: infants}] }
	}


  return (
    <Container reservation>
      <Formik
        initialValues={roomsCompositions}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, handleChange }) => (
          <Form>
            <SplitContainer reservation reverse>
              <LeftContainer reservation>
                {
                  errorMessage && (
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                  )
                }
                <FormRoomsComposition values={values} name={values} />
              </LeftContainer>
              <RightContainer reservation >
                <HotelBookedSummary
                  startDate={startDate}
                  endDate={endDate}
                  hotel={hotel}
                  isInfo={false}
                  nbRooms={get(values, 'rooms').length}
                  disabledButton={false}
                  step={1}
                />
              </RightContainer>
            </SplitContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BookingRoomsComposition;
