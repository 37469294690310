import React, { useContext } from 'react';
import styled,{ css } from 'styled-components';
import { navigate } from '@reach/router';
import BookingContext from 'contexts/booking';
import { Container } from 'ui/containers';
import {
  Title,
  SplitContainer,
  LeftContainer,
  RightContainer,
} from 'ui';
import { Form, SegmentedControlField } from 'components/Forms';
import { Formik, FieldArray } from 'formik';
import HotelBookedSummary from 'containers/Booking/HotelBookedSummary';
import { get, flatten, find } from 'lodash';
import {
  birthdateFormatDate,
} from 'utils/date';
import * as Yup from 'yup';
import { ERRORS } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useGTM } from '@tymate/react-gtm';
import Input from 'components/UI/Input';
import { BookingPolicies } from './BookingPolicies';

const Summary = styled.div`
  position: sticky;
  top: 0px;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const FlexBlock = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
	padding-left: 15px;
	margin-top:15px;
`;

const InputContainer = styled.div`
  flex:1;
  margin-right: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const FieldsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
`;

const RoomsContainer = styled.div`
  align-items: left;
  margin-top: 30px;
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const RoomWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
  margin-right: ${({ theme }) => theme.spacing()};
`;


const SubTitle = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  font-weight: 800;
  font-size: 20px;
`;

const TYPE_MAPPINGS = {
  adult: 'Adulte',
  child: 'Enfant',
};

const ChildBirthdate = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-size: 14px;
  padding-left:15px;
`;

const TopDetail = styled.div`
  margin: 0;
	border-left: 3px solid #67CB8B;
	padding-left: 15px;

`;

const Refer = styled.span`
  margin-left:10px;
`

const RoomTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #67CB8B;
  ${props =>
  props.condition &&
  css`
      color: #000;
    `}
`;

const RoomPerson = ({ id, type, index, birthdate, isRoomLeader }) => {
  const { t } = useTranslation();
  return (
      <>
      <TopDetail>
          <SubTitle>
            {TYPE_MAPPINGS[type]} {index}
            {isRoomLeader && <Refer>{t('booking.details.summary.refer')}</Refer>
            }
            <ChildBirthdate>
              {birthdate && `${t('booking.details.summary.birthdate', {date:birthdateFormatDate(birthdate)})}`}
            </ChildBirthdate>
          </SubTitle>
          <FieldsContainer>
            <SegmentedControlField
                name={`${id}.title`}
                options={[
                  { label: 'M', value: 'Mr' },
                  { label: 'Mme', value: 'Mrs' },
                  { label: 'Autre', value: 'Miss' },
                ]}
            />
          </FieldsContainer>
        </TopDetail>
        <FieldsContainer>
          <FlexBlock>
            <InputContainer>
              <Input
                label={t('booking.details.summary.firstNameTitle')}
                name={`${id}.firstname`}
                type="text"
                placeholder={t('booking.details.summary.firstNameEnter')}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label={t('booking.details.summary.lastNameTitle')}
                name={`${id}.lastname`}
                type="text"
                placeholder={t('booking.details.summary.lastNameEnter')}
              />
            </InputContainer>
          </FlexBlock>
        </FieldsContainer>
      </>
  );
};

const BookingInfo = () => {
  const {
    hotel,
    startDate,
    endDate,
    searchQuery,
    koediaSearch,
    koediaSessionExpiryDate,
    koediaRateDetails,
    onSetRoomGuests,
    koediaPlans,
    selectedPlanId,
    roomGuests
  } = useContext(BookingContext);

  const { pushDataLayer } = useGTM();
  const { t } = useTranslation();

  const rooms = get(koediaSearch, 'rooms', []);
  const peopleIds = flatten(
      rooms.map(({ people }) => people.filter(({ type }) => type !== 'infant').map(({ id }) => id)),
  );


  const selectedPlan = find(koediaPlans, { id: selectedPlanId }) || {};

  const handleSubmit = values => {
    onSetRoomGuests({ roomGuests: values });
    pushDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 4,
          },
        },
      },
    });

    navigate(`/booking/${hotel.id}/discount?${searchQuery}`);
  };

  let bookingInfos = {}
	if (roomGuests){
		bookingInfos = JSON.parse(JSON.stringify(roomGuests));
	}else{
		bookingInfos = {
      policiesConsent: false,
      ...peopleIds.reduce(
          (obj, id) => ({
            ...obj,
            [id]: { title: '', firstname: '', lastname: '' },
          }),
          {},
      ),
    }
	}

  return (
      <Container reservation>
        <Formik
            initialValues={bookingInfos}
            validationSchema={Yup.object().shape({
              policiesConsent: Yup.boolean().equals([true], ERRORS.REQUIRED),
              ...peopleIds.reduce(
                  (obj, id) => ({
                    ...obj,
                    [id]: Yup.object().shape({
                      title: Yup.string().required(ERRORS.REQUIRED),
                      firstname: Yup.string().required(ERRORS.REQUIRED),
                      lastname: Yup.string().required(ERRORS.REQUIRED),
                    }),
                  }),
                  {},
              ),
            })}
            onSubmit={handleSubmit}
        >
          {({ isValid, values }) => (
            <Form>
                <SplitContainer reverse>
                  <LeftContainer reservation>
                <Title>{t('booking.info.roomInfo')}</Title>
                    <FieldArray name="rooms">
                      {() => (
                          <RoomsContainer>
                            {rooms.map(({ people }, roomIndex) => {
                              return (
                                  <RoomWrapper key={roomIndex}>
                                    <RoomTitle>
                                      {t('hotel.room.roomSelectionIndex',{roomIndex:roomIndex+1})}
                                      </RoomTitle>
                                    <div>
                                      {people
                                          .filter(({ type }) => type === 'adult')
                                          .map(({ id, type }, index) => (
                                              <RoomPerson
                                                  id={id}
                                                  type={type}
                                                  key={`adult-${index}`}
                                                  index={index + 1}
                                                  isRoomLeader={index === 0}
                                              />
                                          ))}
                                      {people
                                          .filter(({ type }) => type === 'child')
                                          .map(({ id, type, birthdate }, index) => (
                                              <RoomPerson
                                                  key={`child-${index}`}
                                                  id={id}
                                                  type={type}
                                                  index={index + 1}
                                                  birthdate={birthdate}
                                              />
                                          ))}
                                    </div>
                                  </RoomWrapper>
                              );
                            })}
                          </RoomsContainer>
                      )}
                    </FieldArray>

                    {koediaRateDetails && (
                        <BookingPolicies koediaRateDetails={koediaRateDetails} startDate={startDate}>

                        </BookingPolicies>
                    )}
                  </LeftContainer>
                  <RightContainer reservation>
                    <Summary>
                      <HotelBookedSummary
                        expiryDate={koediaSessionExpiryDate}
                        startDate={startDate}
                        endDate={endDate}
                        hotel={hotel}
                        disabledButton={!Boolean(isValid)}
                        nbRooms={rooms.length}
                        selectedPlan={selectedPlan}
                        isConditions={values.policiesConsent}
                        isInfo={true}
                        step={3}
                      />
                    </Summary>
                  </RightContainer>
                </SplitContainer>
              </Form>
          )}
        </Formik>
      </Container>
  );
};

export default BookingInfo;
