import React, {
  useMemo,
  useReducer,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Router } from '@reach/router';
import { useAsync } from 'react-async';
import { useDeepCompareEffect } from 'react-use';
import { get, flatten, find, isEqual } from 'lodash';
import uuid from 'uuid/v4';
import {
  getHotel,
  searchKoediaDetails,
  getKoediaRateDetails,
} from 'api/hotels';
import { createBooking } from 'api/bookings';
import Layout from 'components/Layout';
import BookingContext from 'contexts/booking';
import BookingRooms from './BookingRooms';
import BookingInfo from './BookingInfo';
import BookingRoomsComposition from './BookingRoomsComposition';
import BookingDiscount from './BookingDiscount';
import BookingValidation from './BookingValidation';
import { parseSearch, getItemAsArray } from 'utils';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInYears from 'date-fns/difference_in_years';
import addMinutes from 'date-fns/add_minutes';
import { bookingFormatDate } from 'utils/date';
import TimeoutBookingModal from 'components/UtilModal/TimeoutBookingModal';
import StepBooking from './StepBooking'
import { navigate } from '@reach/router';
const bookingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BOOKING_PARAMS':
      return {
        ...state,
        bookingParams: action.bookingParams,
      };
    case 'RESET_KOEDIA_DATA':
      return {
        bookingParams: state.bookingParams,
        koediaSearch: null,
        koediaPlans: [],
        selectedPlanId: null,
        selectedRooms:null,
        roomGuests:null,
      };
    case 'SET_KOEDIA_SESSION':
      const currentDate = new Date();
      return {
        ...state,
        koediaSession: action.koediaSession,
        koediaSessionDate: currentDate.toISOString(),
        koediaSessionExpiryDate: addMinutes(currentDate, 20).toISOString(),
      };
    case 'SET_KOEDIA_SEARCH':
      return {
        ...state,
        koediaSearch: action.koediaSearch,
        koediaPlans: [],
        selectedPlanId: null,
        selectedRooms:null,
        roomGuests:null,
      };
    case 'SET_KOEDIA_PLANS':
      return {
        ...state,
        koediaPlans: action.koediaPlans,
      };
    case 'SELECT_PLAN':
      return {
        ...state,
        selectedPlanId: action.selectedPlanId,
        selectedRooms: action.selectedRooms,
      };
    case 'SET_KOEDIA_RATE_DETAILS':
      return {
        ...state,
        koediaRateDetails: action.rateDetails,
      };
    case 'SET_ROOM_GUESTS':
      return {
        ...state,
        roomGuests: action.roomGuests,
      };
    case 'SET_CUSTOMER_BOOKING_ID':
      return {
        ...state,
        customerBookingId: action.customerBookingId,
      };
    case 'SET_BOOKING':
      return {
        ...state,
        booking: action.booking,
      };
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCode: action.promoCode,
      };
    default:
      return state;
  }
};

const isKoediaSessionStale = bookingState => {
  if (!bookingState.koediaSessionDate) {
    return true;
  }
  if (differenceInMinutes(new Date(), bookingState.koediaSessionDate) >= 15) {
    return true;
  }
  return false;
};

const BookingTunnel = ({ location, hotel, step }) => {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const initialState = useMemo(() => {
    try {
      const bookingState =
        JSON.parse(localStorage.getItem('bookingState')) || {};
      if (!isKoediaSessionStale(bookingState)) {
        return bookingState;
      }
      localStorage.removeItem('bookingState');
      return {};
    } catch (e) {
      return {};
    }
  }, []);

  const [state, dispatch] = useReducer(bookingReducer, initialState);
  window.dispatch = dispatch;
  const {
    bookingParams,
    koediaSession,
    koediaSessionExpiryDate,
    koediaPlans,
    selectedPlanId,
    koediaRateDetails,
    koediaSearch,
    roomGuests,
    customerBookingId,
    booking,
    promoCode,
    selectedRooms
  } = state;

  const koediaTimeoutRef = useRef();

  const { startDate, endDate, ageCategories
  } = bookingParams || {};

  const searchQuery = useMemo(() => get(location, 'search').substring(1), [
    location,
  ]);

  useDeepCompareEffect(() => {
    localStorage.setItem('bookingState', JSON.stringify(state));
  }, [state]);

  const { run: fetchKoediaDetails, isPending: isLoading } = useAsync({
    deferFn: useCallback(([hotel, startDate, endDate, koediaSearch]) => {
      if (!hotel || !startDate || !endDate || !koediaSearch) {
        return;
      }

      return searchKoediaDetails({
        search: {
          startAt: startDate,
          endAt: endDate,
          lang: 'fre',
          currency: 'EUR',
          accomtype: 'HOTEL',
          accomcodes: [hotel.koediaId],
          matchingrule: 'room_v2',
          rooms: get(koediaSearch, 'rooms', []).map(({ people }) => {
            return {
              adults: people.filter(({ type }) => type === 'adult').length,
              children: people.filter(({ type }) => type === 'child').length,
              infants: people.filter(({ type }) => type === 'infant').length,
              childrenAges: people
                .filter(({ type }) => type === 'child')
                .map(({ birthdate }) =>
                  differenceInYears(startDate, birthdate),
                ),
              count: 1,
            };
          }),
        },
      }).then(koediaDetails => ({ koediaDetails, koediaSearch }));
    }, []),
    onResolve: useCallback(
      ({ koediaDetails, koediaSearch }) => {
        if (koediaTimeoutRef.current) {
          clearTimeout(koediaTimeoutRef.current);
        }

        koediaTimeoutRef.current = setTimeout(
          handleKoediaTimeout(),
          20 * 60 * 1000,
        );

        dispatch({
          type: 'SET_KOEDIA_SESSION',
          koediaSession: get(koediaDetails, 'sessionId'),
        });
        const possibilities =
          get(koediaDetails, 'data.possibilitiesList.possibility') || [];

        const koediaPlans = flatten(
          possibilities.map(possibility =>
            getItemAsArray(possibility.roomPlans).map(({ roomPlan }) => ({
              id: uuid(),
              plan: getItemAsArray(roomPlan),
              possibility,
            })),
          ),
        );
        dispatch({ type: 'SET_KOEDIA_PLANS', koediaPlans });
      },
      // eslint-disable-next-line
      [koediaTimeoutRef, hotel, startDate, endDate, koediaSearch, state],
    ),
  });

  const handleKoediaTimeout = () => () => {
    setShowTimeoutModal(true);
    dispatch({ type: 'RESET_KOEDIA_DATA' });
  };

  useDeepCompareEffect(() => {
    const params = parseSearch(searchQuery);
    const bookingParams = {
      hotelId: hotel.koobHotelId,
      ...params,
    };
    const hasParamsChanged =
      state.bookingParams && !isEqual(bookingParams, state.bookingParams);

    dispatch({
      type: 'SET_BOOKING_PARAMS',
      bookingParams,
    });

    if (koediaSearch && hasParamsChanged) {
      dispatch({ type: 'RESET_KOEDIA_DATA' });
      fetchKoediaDetails(hotel, params.startDate, params.endDate, koediaSearch);
    }
  }, [hotel, searchQuery, koediaSearch]);

  const { run: fetchKoediaRateDetails } = useAsync({
    deferFn: useCallback(
      ([{ possibility, plan }]) => {
        return getKoediaRateDetails({
          rateDetails: {
            startAt: startDate,
            endAt: endDate,
            sessionId: koediaSession,
            currency: 'EUR',
            lang: 'fre',
            supplierCode: possibility?.['@suppliercode'],
            rooms: (plan || []).map((room, index) => {
              const childrenAges = get(koediaSearch, ['rooms', index, 'people'])
                .filter(({ type }) => type === 'child')
                .map(({ birthdate }) =>
                  differenceInYears(startDate, birthdate),
                );
                return {
                id: room['@roomid'],
                count: 1,
                childrenAges,
              };
            }),
          },
        });
      },
      [startDate, endDate, koediaSession, koediaSearch],
    ),
    onResolve: useCallback(({ data }) => {
      dispatch({ type: 'SET_KOEDIA_RATE_DETAILS', rateDetails: data });
    }, []),
  });

  const handleKoediaSearch = ({ koediaSearch }) => {
    dispatch({ type: 'SET_KOEDIA_SEARCH', koediaSearch });
    fetchKoediaDetails(hotel, startDate, endDate, koediaSearch);
  };

  const handleSelectRoomPlan = async ({ roomPlanId,rooms }) => {
    dispatch({ type: 'SELECT_PLAN', selectedPlanId: roomPlanId,selectedRooms:rooms });
    const selectedPlan = find(koediaPlans, { id: roomPlanId }) || {};
    const { plan, possibility } = selectedPlan;
    fetchKoediaRateDetails({ possibility, plan });
  };

  const handleSetRoomGuests = ({ roomGuests }) => {
    dispatch({ type: 'SET_ROOM_GUESTS', roomGuests });
  };

  const handleSetPromoCode = ({ promoCode }) => {
    dispatch({ type: 'SET_PROMO_CODE', promoCode });
  };

  const handleRestartBooking = () => {
    setShowTimeoutModal(false);
    navigate(`/booking/${hotel.id}?${searchQuery}`);
  };

  const handleCreateBooking = useCallback(
    async values => {
      const selectedPlan = find(koediaPlans, { id: selectedPlanId }) || {};
      const { plan, possibility } = selectedPlan;
      const customerBookingId = uuid();

      dispatch({ type: 'SET_CUSTOMER_BOOKING_ID', customerBookingId });

      const bookingPayload = {
        booking: {
          hotelId: hotel.koobHotelId,
          startAt: startDate,
          endAt: endDate,
          sessionId: koediaSession,
          customerBookingRef: customerBookingId,
          accomCode: hotel.koediaId,
          currency: 'EUR',
          lang: 'fre',
          supplierCode: possibility?.['@suppliercode'],
          conditions: values.policiesConsent,
          rooms: (plan || []).map((room, index) => {
            const koediaSearchPeople = get(koediaSearch, [
              'rooms',
              index,
              'people',
            ]);
            const people = koediaSearchPeople.map(item => ({
              ...item,
              ...(get(roomGuests, [item.id]) || {}),
            }));

            const adults = people.filter(({ type }) => type === 'adult');
            const roomLeader = adults[0];

            const pax = [
              ...adults.slice(1),
              ...people
                .filter(({ type }) => type === 'child')
                .map(child => ({
                  ...child,
                  birthdate: bookingFormatDate(child.birthdate),
                })),
            ];

            const nbInfants = people.filter(({ type }) => type === 'infant')
              .length;

            return {
              roomid: room['@roomid'],
              roomLeader,
              ...(nbInfants > 0
                ? {
                    nbCot: nbInfants,
                  }
                : {}),
              pax: pax.map(item => ({
                ...item,
                ...(item.type === 'child' ? { paxtype: 'Child' } : {}),
              })),
            };
          }),
        },
      };

      const booking = await createBooking(bookingPayload);
      dispatch({ type: 'SET_BOOKING', booking });
    },
    [
      hotel,
      koediaPlans,
      koediaSearch,
      koediaSession,
      endDate,
      roomGuests,
      selectedPlanId,
      startDate,
    ],
  );

  return (
    <BookingContext.Provider
      value={{
        isLoading,
        hotel,
        startDate,
        endDate,
        koediaSession,
        koediaPlans,
        koediaSearch,
        koediaRateDetails,
        searchQuery,
        selectedPlanId,
        koediaSessionExpiryDate,
        customerBookingId,
        booking,
        promoCode,
        onKoediaSearch: handleKoediaSearch,
        onSelectRoomPlan: handleSelectRoomPlan,
        onSetRoomGuests: handleSetRoomGuests,
        onPromoCodeSet: handleSetPromoCode,
        onCreateBooking: handleCreateBooking,
        ageCategories:ageCategories,
        currentSelectedRooms:selectedRooms,
        roomGuests:roomGuests
      }}
    >
      <Layout variant="home" noSticky>
        <StepBooking currentStepString={step}/>
        {hotel &&
          (showTimeoutModal ? (
            <TimeoutBookingModal
              hotel={hotel}
              startDate={startDate}
              endDate={endDate}
              koediaSearch={koediaSearch}
              selectedPlanId={selectedPlanId}
              searchQuery={searchQuery}
              onRestartBooking={handleRestartBooking}
            />
          ) : (
            <Router>
              <BookingRoomsComposition path="rooms-composition" />
              <BookingRooms path="rooms" />
              <BookingInfo path="info" />
              <BookingDiscount path="discount" state={state} />
              <BookingValidation path="validation" state={state}/>
            </Router>
          ))}
      </Layout>
    </BookingContext.Provider>
  );
};

const Booking = ({ location, hotelId, match: { step } }) => {
  const { data: hotel, isResolved } = useAsync({
    promiseFn: getHotel,
    id: hotelId,
  });
  if (!isResolved) {
    return <div />;
  }
  return <BookingTunnel location={location} hotel={hotel} step={step} />;
};

export default Booking;
