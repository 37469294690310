import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { get, uniqBy, find } from 'lodash';
import { Formik, Form } from 'formik';
import { navigate } from '@reach/router';
import { Container } from 'ui/containers';
import { Link } from 'gatsby';
import {
  media,
  Buttons,
  Title,
  SpinnerContainer,
  SplitContainer,
  LeftContainer,
  RightContainer,
  EmptyText,
  Button,
} from 'ui';
import { RoomCard } from 'containers/Booking/RoomCards';
import HotelBookedSummary from 'containers/Booking/HotelBookedSummary';
import BookingContext from 'contexts/booking';
import Spinner from 'components/Spinner';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';
import { useGTM } from '@tymate/react-gtm';
import { useTranslation } from 'react-i18next';

const Summary = styled.div`
  position: sticky;
  top: 32px;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const SubTitle = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-weight: 600;
`;

const OptionWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.5) !important;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.295)}
    ${({ theme }) => theme.spacing(0.6)};
  text-align: center;

  ${props =>
  props.greenOption &&
  css`
      background-color: rgba(115, 201, 146, 0.12);
      color: ${({ theme }) => theme.primary} !important;
    `}

  ${props =>
  props.orangeOption &&
  css`
      background-color: #FFE3E3;
      color: #EA5D5D !important;
    `}
`;

const FlexBlock = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing()};
    margin-right: 0;
  }

  ${media.tablet`
    flex-wrap: wrap;

    > * {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing()};
    }
  `}
`;

const OptionCard = ({ room }) => {
  return (
    <OptionWrapper>
      <span>{get(room, 'board.included.adult')}</span>
    </OptionWrapper>
  );
};

const RoomsLine = styled.div`
  position: relative;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  outline: none;
  background-color: #fff;

  border-radius: 30px;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing()};

  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${props =>
  props.isRoomSelected &&
  `
      border: 4px solid #73c992;
    `}
`;

const AvailableRooms = styled.div`
  margin-left: ${({ theme }) => theme.spacing(0)};

  ${media.medium`
    margin-top: 0;
  `}
`;

const RoomInfos = styled.div`
  width:100%;
  padding-bottom: ${({ theme }) => theme.spacing()};
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const OptionInfos = styled.div`
  margin-left: 13px;
  padding-bottom: ${({ theme }) => theme.spacing()};
  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const RoomTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-left: 18px;
  margin-top: -18px;
	color: #67CB8B;
`;

const SelectedRooms = styled.div`
  margin-bottom: 60px;
`

const Radio = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  border-radius: 100%;
  padding-bottom: 26px;
  svg {
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
  }
`;


const RoomPlans = ({rooms, roomPlans,selectedRooms,handleRemoveRoom,setCurrentSelectedRoom,currentSelectedRoom,numberRoomToSelect, ...props }) => {
  const { t } = useTranslation();

  const roomPlansFilteredByCombos = roomPlans.filter((roomPlan) => {
    let valid = true;
    selectedRooms.forEach((room,index) => {
      if (roomPlan.plan[index]['@roomid'] !== room['@roomid']) {
        valid = false;
      }
    });
    return valid;
  });

  const isRoomSelected = (room) => {
    return currentSelectedRoom && currentSelectedRoom['@roomid']===room['@roomid']
  }
  let  countRoom = Number(numberRoomToSelect) === 1 ?  numberRoomToSelect + t('hotel.room.roomsCountFirst') : numberRoomToSelect + t('hotel.room.roomsCountOther')
  return (
    <>
      <Title>{t('hotel.room.roomSelection',{index:countRoom})}</Title>
      <SubTitle>{t('hotel.offer.proposal')}</SubTitle>
      {selectedRooms.length > 0 ?
        <SelectedRooms>
          {selectedRooms.map((room, index) => (
            <RoomsLine>
              <Radio>
                <MdCheckCircle />
              </Radio>
              <RoomTitle>{t('hotel.room.roomSelectionIndex',{roomIndex:index+1})}</RoomTitle>
              <RoomInfos>
                <RoomCard key={room['@roomid']} room={room} roomIndex={index} selected="selected" deleteRoom={handleRemoveRoom}/>
              </RoomInfos>
            </RoomsLine>
          )) }
        </SelectedRooms>
        :
        ''
      }
      <AvailableRooms>
        {roomPlansFilteredByCombos.map(({ plan, possibility }) => {
          return (
            <>
              {plan.slice(numberRoomToSelect-1, numberRoomToSelect).map((room) => (
                <RoomsLine onClick={() => setCurrentSelectedRoom(room)} isRoomSelected={isRoomSelected(room)} >
                  <Radio>
                    {isRoomSelected(room) ? <MdCheckCircle /> : <MdRadioButtonUnchecked />}
                  </Radio>
                  <RoomInfos>
                    <RoomCard key={plan.id} room={room} possibility={possibility} />
                  </RoomInfos>
                  <OptionInfos>
                    <FlexBlock>
                      {uniqBy(plan, 'board.included.adult').map((room, index) => (
                        <OptionCard room={room} />
                      ))}
                      {
                        room.refundable ? (
                          <OptionWrapper greenOption>
                            {t('booking.details.refundable')}
                          </OptionWrapper>
                        ) : (
                          <OptionWrapper orangeOption>
                            {t('booking.details.nonRefundable')}
                          </OptionWrapper>
                        )
                      }
                      {
                        room.extrabed && (
                          <OptionWrapper>{t('hotel.room.moreBed')}</OptionWrapper>
                        )}
                    </FlexBlock>
                  </OptionInfos>
                </RoomsLine>
              ))}
            </>
          )
        })}
      </AvailableRooms>
    </>
  );
};

const BookingRooms = () => {
  const {
    isLoading,
    hotel,
    startDate,
    endDate,
    koediaPlans,
    searchQuery,
    onSelectRoomPlan,
    koediaSearch,
    koediaSessionExpiryDate,
    currentSelectedRooms
  } = useContext(BookingContext);
  const { pushDataLayer } = useGTM();

  let defaultSelectedRooms = [];
  let defaultCurrentSelectedRoom;
  if (currentSelectedRooms) {
    const rooms = JSON.parse(JSON.stringify(currentSelectedRooms));
    defaultSelectedRooms = rooms.slice(0,currentSelectedRooms.length-1);
    defaultCurrentSelectedRoom = rooms[rooms.length-1];
  }

  const [selectedRooms, setSelectedRooms] = useState(defaultSelectedRooms)
  const [currentSelectedRoom, setCurrentSelectedRoom] = useState(defaultCurrentSelectedRoom)

  const [numberRoomToSelect, setNumberRoomToSelect] = useState(defaultSelectedRooms.length+1)

  const handleRemoveRoom = (roomIndex) => {
    setSelectedRooms(selectedRooms.slice(0,roomIndex))
    setCurrentSelectedRoom(undefined);
    setNumberRoomToSelect(roomIndex+1);
  };
  const rooms = get(koediaSearch, 'rooms', []);
  const totalRoomNumber = rooms.length;
  const validateCurrentRoom = () => {
    if (numberRoomToSelect !== totalRoomNumber) {
      setNumberRoomToSelect(numberRoomToSelect+1);
      setSelectedRooms([...selectedRooms,currentSelectedRoom]);
      setCurrentSelectedRoom(undefined);
      return;
    }
    const newSelectedRooms = [...selectedRooms,currentSelectedRoom];
    setSelectedRooms(newSelectedRooms);
    setCurrentSelectedRoom(undefined);
    const roomPlan = koediaPlans.filter((roomPlan) => {
      let valid = true;
      newSelectedRooms.forEach((room,index) => {
        if (roomPlan.plan[index]['@roomid'] !== room['@roomid']) {
          valid = false;
        }
      });
      return valid;
    })[0].id;
    onSelectRoomPlan({ roomPlanId: roomPlan, rooms: newSelectedRooms });
    const { plan } = find(koediaPlans, { id: roomPlan });
    pushDataLayer({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: plan.map(room => ({
            id: room['@roomid'],
            name: room.roomDescription,
            brand: 'Feelingo',
            category: 'Room',
            quantity: 1,
            price: get(room, 'roomPrice.total.@rack'),
          })),
        },
      },
    });
    pushDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 3,
          },
          products: plan.map(room => ({
            id: room['@roomid'],
            name: room.roomDescription,
            brand: 'Feelingo',
            category: 'Room',
            quantity: 1,
            price: get(room, 'roomPrice.total.@rack'),
          })),
        },
      },
    });
    navigate(`/booking/${hotel.id}/info?${searchQuery}`);
  }

  const { t } = useTranslation()

  return (
    <Container reservation>
      <Formik
        initialValues={{
          roomPlan: null,
        }}
      >
        {({ values }) => (
          <Form>
            <SplitContainer reservation reverse>
              <LeftContainer reservation>
                {isLoading ? (
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                ) : koediaPlans && koediaPlans.length > 0 ? (
                  <RoomPlans name="roomPlan" rooms={rooms} roomPlans={koediaPlans}
                             selectedRooms={selectedRooms}
                             numberRoomToSelect={numberRoomToSelect}
                             currentSelectedRoom={currentSelectedRoom}
                             setCurrentSelectedRoom={setCurrentSelectedRoom}
                             handleRemoveRoom={handleRemoveRoom}
                  />
                ) : (
                  <>
                    <EmptyText>{t('hotel.room.nonDispo')}</EmptyText>
                    <Buttons hasTopMargin style={{ justifyContent: 'center' }}>
                      <Button variant="primary" as={Link} to="/search">
                        {t('utils.home')}
                      </Button>
                    </Buttons>
                  </>
                )}

              </LeftContainer>
              <RightContainer reservation>
                <Summary>
                  <HotelBookedSummary
                    startDate={startDate}
                    endDate={endDate}
                    hotel={hotel}
                    isInfo={false}
                    isLoading={isLoading}
                    disabledButton={!Boolean(currentSelectedRoom)}
                    step={2}
                    rooms={selectedRooms}
                    totalRoomNumber={totalRoomNumber}
                    currentSelectedRoom={currentSelectedRoom}
                    handleNextStep={validateCurrentRoom}
                    expiryDate={koediaSessionExpiryDate}
                  />
                </Summary>
              </RightContainer>
            </SplitContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BookingRooms;
