import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { get, range } from 'lodash';
import { FieldArray } from 'formik';
import { MdClose,MdRemove, MdAdd } from 'react-icons/md';
import { DateField } from 'components/SingleDateField';

import {
  media,
  TextButton,
	ButtonReset,
	Button,
  Title
} from 'ui';
import { useTranslation } from 'react-i18next';

import { Stack } from '@tymate/margaret';

const DeleteButton = styled(TextButton)`
  position: relative;
  left: 98.5%;
	margin-bottom: -12px;
`;

const AgeCategoryDetail = styled.dl`
  margin: 0;
	border-left: 3px solid #67CB8B;
	padding-left: 10px;
  dt {
    ${({ theme }) => theme.fontStyles.h3};
  }

  dd {
    margin-left: 0;
    color: ${({ theme }) => theme.textLight};
    ${({ theme }) => theme.fontStyles.body};
  }
`;

const AgesContainer = styled.div`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(0.75)};
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  ${media.tablet`
    width: 100%;
    max-height: initial;
    padding: ${({ theme }) => theme.spacing()}
      ${({ theme }) => theme.spacing(1.5)};
  `}

  ${media.desktop`
    width: 100%;
  `}
`;

const RoomsContainer = styled.div`
  align-items: left;

  ${media.medium`
    margin-top:0;
  `}

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

const AgeCategories = styled(Stack).attrs({
  direction: 'column',
})`
  > * {
    padding: ${({ theme }) => theme.spacing()} 0;
  }
  > * + * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

const StepperWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 1,
})`
  flex-shrink: 0;
  ${({ theme }) => theme.fontStyles.h3};
  font-weight: normal;

  svg {
    font-size: ${({ theme }) => theme.size(1.5)};
  }
`;

const AgeCategory = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 3,
  alignX: 'space-between',
})`
  width: 300px;
`;

const RoomWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing()};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const RoomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #fff;
`;

const RoomTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #67CB8B;
`;

const DateChildren = styled.div`
	margin-left: 8px;
	display:inline-block;
`;

const DateChildrenTitle = styled.div`
  margin-left: 10px;
	display:block;
`;
const Stepper = ({ value, onIncrement, onDecrement }) => {
  return (
    <StepperWrapper>
      <ButtonReset
        onClick={e => {
          e.preventDefault();
          onDecrement();
        }}
      >
        <MdRemove size={28} color={'#FFA24B'} style={{background:'#FFF1E3', borderRadius:'20px'  }} />
      </ButtonReset>
      <div>{value}</div>
      <ButtonReset
        onClick={e => {
          e.preventDefault();
          onIncrement();
        }}
      >
        <MdAdd size={25} color={'#FFA24B'} style={{background:'#FFF1E3', borderRadius:'20px' }} />
      </ButtonReset>
    </StepperWrapper>
  );
};


const FormRoomsComposition = ({values}) => {

	const [rooms, setRooms] = useState(get(values, 'rooms', []));

	useEffect(() => {
		setRooms(get(values, 'rooms', []))
  },[values]);

 const increment = (roomIndex, key) => {
	if (
		key.localeCompare("adults") !== 0 &&
		key.localeCompare("children") !== 0 &&
		key.localeCompare("infants") !== 0
	) {
		return;
	}
	setRooms(
		rooms.map((room, index) => {
			if (index === roomIndex) {
				room[key]++;
			}
			return room;
		})
	);
};

const decrement = (roomIndex, key) => {
	if (
		key.localeCompare("adults") !== 0 &&
		key.localeCompare("children") !== 0 &&
		key.localeCompare("infants") !== 0
	) {
		return;
	}

	setRooms(
		rooms.map((room, index) => {
			if (index === roomIndex) {
				room[key]--;
			}
			return room;
		})
	);
};

  const { t } = useTranslation();

  return (
		<>
      <Title>{t('hotel.rooms.compo')}</Title>
			<FieldArray name="rooms">
				{({ push, remove }) => (
				 <RoomsContainer>
						{rooms.map((_, roomIndex) => (
							<AgesContainer>
                {roomIndex > 0 && (
                  <DeleteButton
                    type="button"
                    onClick={() => remove(roomIndex)}
                  >
                    <MdClose size={18} />
                  </DeleteButton>
                )}
								<RoomLine>
									<AgeCategories>
										<RoomWrapper key={roomIndex}>
											<RoomTitle>{t('hotel.room.roomTitle',{ index: roomIndex +1 })}</RoomTitle>
											<AgeCategory>
												<AgeCategoryDetail>
													<dt>{t('hotel.room.adult')}</dt>
													<dd>{t('hotel.room.adultAge')}</dd>
												</AgeCategoryDetail>
												<Stepper
													value={
														rooms[roomIndex] &&
														rooms[roomIndex].adults
													}
													name={`rooms.${roomIndex}.adults`}
													onDecrement={() =>
														rooms[roomIndex] &&
														rooms[roomIndex].adults > 0 &&
														decrement(
															roomIndex,
															"adults"
														)
													}
													onIncrement={() => {
														increment(
															roomIndex,
															"adults"
														);
													}}
												/>
											</AgeCategory>
											<AgeCategory>
												<AgeCategoryDetail>
													<dt>{t('hotel.room.children')}</dt>
													<dd>{t('hotel.room.childrenAge')}</dd>
												</AgeCategoryDetail>
												<Stepper
													value={
														rooms[roomIndex] &&
														rooms[roomIndex].children
													}
													name={`rooms.${roomIndex}.children`}
													onDecrement={() =>
														rooms[roomIndex] &&
														rooms[roomIndex].children > 0 &&
														decrement(
															roomIndex,
															"children"
														)
													}
													onIncrement={() => {
														increment(
															roomIndex,
															"children"
														);
													}}
												/>

											</AgeCategory>
											{
													get(values, ['rooms', roomIndex, 'children'])	 ?  <DateChildrenTitle>{t('hotel.room.birthdate')}</DateChildrenTitle>  : ''
												 }
													{

													(
														range(
															0,
															get(values, ['rooms', roomIndex, 'children']) ||
																{},
														) || []
													).map((_, childrenIndex) => (
														<DateChildren>
															<DateField
																name={`rooms.${roomIndex}.childrenAge.${childrenIndex}.birthday`}
																full
                                variant="booking"
                                datePlaceholder={t('hotel.room.date')}
                                clearDateDisabled
                                allowPast
                                isMonthAndYearSelectionnable
								actionOnfocus={function() {
									return null
								  }}
															/>
														</DateChildren>
													))}
											<AgeCategory>
												<AgeCategoryDetail>
													<dt>{t('hotel.room.babys')}</dt>
													<dd>{t('hotel.room.babysAge')}</dd>
												</AgeCategoryDetail>
												<Stepper
													value={
														rooms[roomIndex] &&
														rooms[roomIndex].infants
													}
													onDecrement={() =>
														rooms[roomIndex] &&
														rooms[roomIndex].infants > 0 &&
														decrement(
															roomIndex,
															"infants"
														)
													}
													onIncrement={() => {
														increment(
															roomIndex,
															"infants"
														);
													}}
												/>
											</AgeCategory>
										</RoomWrapper>
									</AgeCategories>
								</RoomLine>
							</AgesContainer>
						))}
						<Button
							type="button"
							variant="booking"
							onClick={() => push({ adults: 0, children: 0, infants: 0 })}>
							{t('hotel.room.add')}
          	</Button>
				</RoomsContainer>
				)}
			</FieldArray>
		</>
  );
};

export default FormRoomsComposition;
