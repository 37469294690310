import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { media, Button, Buttons } from 'ui';
import { useTranslation } from 'react-i18next';

const LightBlock = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
  margin-right: auto;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  max-width: 1000px;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  text-align: center;

  > * {
    margin-top: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing(2)};
    }
  }

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) =>
    theme.spacing(6)};
  `}
`;

const TimeoutBookingModal = ({
  hotel,
  searchQuery,
  onRestartBooking,
  startDate,
  endDate,
  koediaSearch,
}) => {
  const { t } = useTranslation();
  return (
    <LightBlock>
      <span>{t('booking.timeout.text')}</span>
      <Buttons>
        <Button
          variant="primary"
          onClick={() => {
            navigate(`/booking/${hotel.id}/rooms?${searchQuery}`);
            onRestartBooking({ hotel, startDate, endDate, koediaSearch });
          }}
        >
          {t('booking.timeout.again')}
        </Button>
      </Buttons>
    </LightBlock>
  );
};

export default TimeoutBookingModal;
