import React, { useContext, useState, useEffect, useRef } from 'react';
import { navigate, useLocation } from '@reach/router';
import styled from 'styled-components';
import { get } from 'lodash';
import BookingContext from 'contexts/booking';
import AuthContext from 'contexts/auth';
import { Container } from 'ui/containers';
import { SplitContainer, LeftContainer, RightContainer, SpinnerContainer,media} from 'ui';
import Spinner from 'components/Spinner';
import HotelBookedSummary from 'containers/Booking/HotelBookedSummary';
import Discount from 'containers/Booking/Discount';
import AuthForm from 'components/AuthForm';
import { find } from 'lodash';
import { useGTM } from '@tymate/react-gtm';
import { getItemAsArray } from 'utils';
import {
  initializeBookingPayment,
  confirmBookingPayment,
  getBooking,
} from 'api/bookings';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

const Summary = styled.div`
  position: sticky;
  top: 32px;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const RoomTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const AuthFormContainer = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
`;

const AuthFormContentContainer = styled.div`
  flex:1;
  max-width:600px;
`;

const DiscountContainer = styled.div`
  position: relative;
  display: block;
  outline: none;
  background-color: #f7f7f7;

  border-radius: 30px;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing()};

  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  ${media.mobile`
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing()};
  `}
`;

const Paygreen = styled.iframe`
  width: 100%;
  min-height: 100vh;
`;

const PaymentLayout = ({
  children,
  koediaSessionExpiryDate,
  startDate,
  endDate,
  booking,
  hotel,
  discountPrice,
  originalPrice,
  selectedPlan,
  promoAmount,
  handleNextStep,
  isPaymentProcessStarted,
  isPaymentAuthorized,
  isBookingLoaded,
  hasDiscountCode

}) => (
  <Container reservation>
    <SplitContainer reverse>
      <LeftContainer reservation>{children}</LeftContainer>
      <RightContainer reservation>
          <Summary>
            <HotelBookedSummary
              startDate={startDate}
              endDate={endDate}
              isInfo={false}
              nbRooms={get(booking, 'rooms', []).length}
              hotel={hotel}
              discountPrice={discountPrice}
              disabledButton={isPaymentProcessStarted || isPaymentAuthorized || !isBookingLoaded || hasDiscountCode === null}
              isStepDiscount
              step={4}
              selectedPlan={selectedPlan}
              originalPrice={originalPrice}
              expiryDate={koediaSessionExpiryDate}
              handleNextStep={handleNextStep}
              promoAmount={promoAmount}
            />
          </Summary>
        </RightContainer>
    </SplitContainer>
  </Container>
);

const BookingDiscount = ({ state }) => {
  const {
    hotel,
    startDate,
    endDate,
    koediaSessionExpiryDate,
    booking,
    onCreateBooking,
    onPromoCodeSet,
    koediaPlans,
    selectedPlanId,
    promoCode,
  } = useContext(BookingContext);

  const { isAuthenticated } = useContext(AuthContext);
  const [hasDiscountCode, setHasDiscountCode] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(false);
  const [promoAmount, setPromoAmount] = useState(false);
  const [isPaymentProcessStarted, setIsPaymentProcessStarted] = useState(false);
  const location = useLocation();
  const selectedPlan = find(koediaPlans, { id: selectedPlanId }) || {};
  const { t } = useTranslation();

  const msgListenerRef = useRef(null);

  const [isPaymentAuthorized, setIsPaymentAuthorized] = useState(false);
  const [isBookingLoaded, setIsBookingLoaded] = useState(false);
  const { pushDataLayer } = useGTM();

  const { data: transaction, run } = useAsync({
    deferFn: ([bookingId]) => initializeBookingPayment({ bookingId }),
  });

  useEffect(() => {
    if (!booking || !booking.id || !isPaymentProcessStarted) {
      return;
    }

    const handleConfirm = async ({ bookingId }) => {
      await confirmBookingPayment({ bookingId });
      await handleSendEvent(bookingId);
      navigate(`/booking/${hotel.id}/validation${location?.search}`);
    };

    const handleMessage = bookingId => msg => {
      if (msg.data && msg.data.type=== 'paymentAuthorized') {
        setIsPaymentAuthorized(true);
        handleConfirm({
          bookingId,
        });
      }
    };

    const handleSendEvent = async bookingId => {
      const { booking } = await getBooking({ bookingId });
      const bookedRoomPlans = getItemAsArray(
        get(
          booking,
          'koobData.response.data.bookItem.roomsPlanBooked.room',
          [],
        ),
      );

      pushDataLayer({
        ecommerce: {
          purchase: {
            actionField: {
              id: get(booking, 'koobBookingId'),
              revenue: get(
                booking,
                'koobData.response.data.bookItem.price.totalPrice.@rack',
              ),
            },
            products: bookedRoomPlans.map((room, index) => {
              const roomId = get(booking, [
                'koobData',
                'rooms',
                index,
                'roomid',
              ]);
              return {
                id: roomId,
                name: room.roomDescription,
                brand: 'Feelingo',
                category: 'Room',
                quantity: 1,
                price: get(room, 'roomPrice.total.@rack'),
              };
            }),
          },
        },
      });
    };

    msgListenerRef.current = handleMessage(booking.id);
    window.addEventListener('message', msgListenerRef.current);
    return () => {
      window.removeEventListener('message', msgListenerRef.current);
    };
  }, [booking, pushDataLayer, isPaymentProcessStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  const originalPrice = (transaction?.data?.amount / 100).toFixed(2) || null;

  const handleNextStep = () => {
    if (!booking) {
      return;
    }
    setIsPaymentProcessStarted(true);
    run(booking.id);
  };

  const paymentLayoutProps = {
    koediaSessionExpiryDate: koediaSessionExpiryDate,
    startDate: startDate,
    endDate: endDate,
    booking: booking,
    hotel: hotel,
    originalPrice: originalPrice,
    discountPrice: discountPrice,
    selectedPlan: selectedPlan,
    promoAmount: promoAmount,
    handleNextStep: handleNextStep,
    isPaymentProcessStarted:isPaymentProcessStarted,
    isPaymentAuthorized:isPaymentAuthorized,
    isBookingLoaded: isBookingLoaded,
    hasDiscountCode: hasDiscountCode
  };

  useEffect(() => {
    if (isAuthenticated) {
      onCreateBooking({ policiesConsent: true }).then(() => {
        setIsBookingLoaded(true);
      });
    }
  }, [isAuthenticated, onCreateBooking]);

  if (!isAuthenticated) {
    return (
        <PaymentLayout {...paymentLayoutProps}>
          <AuthFormContainer>
            <AuthFormContentContainer>
              <AuthForm />
            </AuthFormContentContainer>
          </AuthFormContainer>
        </PaymentLayout>
    );
  }

  if (isPaymentAuthorized) {
    return (
        <PaymentLayout {...paymentLayoutProps}>
          <DiscountContainer>
              <RoomTitle>{t('booking.promotional.questionPromotionalCode2')}</RoomTitle>
              <Discount
                bookingId={booking?.id}
                setDiscountPrice={setDiscountPrice}
                hasDiscountCode={hasDiscountCode}
                setHasDiscountCode={setHasDiscountCode}
                setPromoAmount={setPromoAmount}
                setPromoCode={onPromoCodeSet}
                disableButtons={true}
              />
          </DiscountContainer>
          <SpinnerContainer>
            <Spinner label={t('booking.promotional.confirmPayment')}  />
          </SpinnerContainer>
          {
            promoCode !== undefined ?
              <iframe
                title='iframePayment'
                src={"https://lb.affilae.com/?key=605b1423dc025f1090deb57f-605b1154dc025f1090deb575&id="+booking.id+"&amount="+originalPrice+"&payment=online&voucher="+promoCode}
                frameBorder="0" width="1" height="1"
              />
              :
              <iframe
                title='iframePayment'
                src={"https://lb.affilae.com/?key=605b1423dc025f1090deb57f-605b1154dc025f1090deb575&id="+booking.id+"&amount="+originalPrice+"&payment=online"}
                frameBorder="0" width="1" height="1"
              />
          }
        </PaymentLayout>
    );
  }

  if (isPaymentProcessStarted) {
    let paygreenUrl = `${transaction?.data?.url}?display=insite`;
    return (
      <PaymentLayout {...paymentLayoutProps}>
        <DiscountContainer>
          <RoomTitle>{t('booking.promotional.questionPromotionalCode2')}</RoomTitle>
          <Discount
            bookingId={booking?.id}
            setDiscountPrice={setDiscountPrice}
            setPromoAmount={setPromoAmount}
            hasDiscountCode={hasDiscountCode}
            setHasDiscountCode={setHasDiscountCode}
            setPromoCode={onPromoCodeSet}
            disableButtons={true}
          />
        </DiscountContainer>
        {transaction ? <Paygreen src={paygreenUrl} /> : <SpinnerContainer>
          <Spinner label={t('booking.promotional.initialisePayment')}  />
        </SpinnerContainer>}

      </PaymentLayout>
    );
  }

  return (
      <PaymentLayout {...paymentLayoutProps}>
        <DiscountContainer>
          <RoomTitle>{t('booking.promotional.questionPromotionalCode2')}</RoomTitle>
          <Discount
            bookingId={booking?.id}
            setDiscountPrice={setDiscountPrice}
            setPromoAmount={setPromoAmount}
            hasDiscountCode={hasDiscountCode}
            setHasDiscountCode={setHasDiscountCode}
            setPromoCode={onPromoCodeSet}
            disableButtons={!isBookingLoaded}
          />
        </DiscountContainer>
        {!isBookingLoaded && <SpinnerContainer>
          <Spinner label={t('booking.promotional.bookingCreationInProgress')}  />
        </SpinnerContainer>
        }

      </PaymentLayout>
  );
};

export default BookingDiscount;
