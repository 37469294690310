import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { MdCheck } from 'react-icons/md';
import { Container } from 'ui/containers';
import { media } from 'ui';
import { navigate } from '@reach/router';

const StepBlock = styled.div`
  box-sizing: border-box;
  margin: 0 0 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-top: ${({ theme }) => theme.spacing(2)};
  > * {
    box-sizing: border-box;
  }

  ${media.mobile`
    display:none;
  `}
`;

const WrapperStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  list-style: none;

  &:after {
    content: '';
    position: absolute;
    top: 25%;
    height: 3px;
    width: 80%;
    left: 60%;
    z-index: 2;
    background: linear-gradient(to left, #e7eaf3 50%, #a3a3a3 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 200ms ease-in-out;
    ${media.tablet`
    width: 65%;
    left: 67%;
    `}
  }

  &:last-child::after {
    content: none;
  }

  &:first-child::before {
    content: none;
  }

  ${props =>
    props.isCurrentStep &&
    css`
      &:after {
        background: linear-gradient(to left, #67cb8b 50%, #67cb8b 50%);
      }
    `}

  ${props =>
    props.isPreviousStep &&
    css`
      &:after {
        background: linear-gradient(to left, #67cb8b 50%, #67cb8b 50%);
      }
    `}
`;

const StepLabel = styled.div`
  flex-wrap: nowrap;
  font-size: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.textLighter};
  text-align: center;

  ${props =>
    props.isCurrentStep &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
  ${props =>
    props.isPreviousStep &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
    ${props =>
      props.isNextStep  &&
      css`
      color: ${({ theme }) => theme.primary};
      `}
`;

const Circle = styled.div`
  position: relative;
  width: 36px;
  height: 38px;
  text-align: center;
  align-items: center;
  color: #a3a3a3;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid #a3a3a3;
  border-radius: 13px;
  opacity: 1;

  ${props =>
    props.isCurrentStep &&
    css`
      background-color: ${({ theme }) => theme.primary};
      border: none;
      color: #fff;
    `}
  ${props =>
    props.isPreviousStep &&
    props.canChangeState &&
    css`
      background-color: #fff;
      border: 2px solid #67cb8b;
      cursor: pointer;
    `}
    ${props =>
      props.isNextStep  &&
      css`
      background-color: ${({ theme }) => theme.primary};
      border: 2px solid ${({ theme }) => theme.primary};
      `}
`;

const Number = styled.div`
  position: absolute;
  color: #a3a3a3;
  width: 100%;
  height: 36px;
  text-align: center;
  transform: translateY(15%);
  ${props =>
    props.isCurrentStep &&
    css`
      color: #fff;
    `}

  ${props =>
    props.isPreviousStep &&
    css`
      color: #67cb8b;
    `}
    ${props =>
      props.isNextStep  &&
      css`
      color: #ffffff;
      `}
`;

const StepNumber = ({
  label,
  number,
  isCurrentStep,
  isPreviousStep,
  navigateToState,
  canChangeState,
  isNextStep,
}) => {
  return (
    <WrapperStep isCurrentStep={isCurrentStep} isPreviousStep={isPreviousStep}>
      <Circle
        canChangeState={canChangeState}
        isCurrentStep={isCurrentStep}
        isPreviousStep={isPreviousStep}
        isNextStep={isNextStep}
        onClick={
          isPreviousStep && canChangeState && navigateToState
            ? () => {
                navigateToState(number);
              }
            : {}
        }
      >
        <Number isCurrentStep={isCurrentStep} isPreviousStep={isPreviousStep} isNextStep={isNextStep}>
          {isPreviousStep ? (
            <MdCheck
              size="20"
              style={{ color: '#67CB8B', marginTop: '-6px' }}
            />
          ) : (
            number
          )}
        </Number>
      </Circle>
      <StepLabel isCurrentStep={isCurrentStep} isPreviousStep={isPreviousStep} isNextStep={isNextStep}>
        {label}
      </StepLabel>
    </WrapperStep>
  );
};

const StepBooking = ({ currentStepString }) => {
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    if (currentStepString === 'rooms') {
      setCurrentStep(1);
    }
    if (currentStepString === 'info') {
      setCurrentStep(2);
    }
    if (currentStepString === 'discount') {
      setCurrentStep(3);
    }
    if (currentStepString === 'validation') {
      setCurrentStep(4);
    }
  }, [currentStepString]);

  const navigateToState = number => {
    if (number >= 1 && number <= 4) {
      setCurrentStep(number);
      navigate(-(currentStep - (number - 1)));
    }
  };

  return (
    <Container steps>
      <StepBlock>
        <StepNumber
          label="Chambres"
          number={1}
          canChangeState={currentStep !== 5}
          isPreviousStep={currentStep > 0}
          navigateToState={navigateToState}
          isCurrentStep={currentStep === 1}
          isNextStep={currentStep === 0}
        />
        <StepNumber
          label="Options"
          number={2}
          canChangeState={currentStep !== 5}
          isPreviousStep={currentStep > 1}
          navigateToState={navigateToState}
          isCurrentStep={currentStep === 2}
          isNextStep={currentStep === 1}
        />
        <StepNumber
          label="Informations"
          number={3}
          canChangeState={currentStep !== 5}
          isPreviousStep={currentStep > 2}
          navigateToState={navigateToState}
          isCurrentStep={currentStep === 3}
          isNextStep={currentStep === 2}
        />
        <StepNumber
          label="Paiement"
          number={4}
          canChangeState={currentStep !== 5}
          isPreviousStep={currentStep > 3}
          navigateToState={navigateToState}
          isCurrentStep={currentStep === 4}
          isNextStep={currentStep === 3}
        />
        <StepNumber
          label="Validation"
          number={5}
          canChangeState={currentStep !== 5}
          isPreviousStep={currentStep > 4}
          isCurrentStep={currentStep === 5}
          isNextStep={currentStep === 4}
        />
      </StepBlock>
    </Container>
  );
};

export default StepBooking;
