import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { MdTrendingFlat,MdCheck } from 'react-icons/md';
import { Grid, Cell } from '../../ui';
import { journeyFormatDate } from '../../utils/date';
import { useTranslation } from 'react-i18next';
import placeholderHotel from '../../assets/images/placeholderHotel.png';

const HotelPic = styled.div`
  background-image: url("${props => props.image}");
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 245px;
`;

const BookingInfo = styled.div`
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 30px;
  padding: 30px;

`;

const TitleCard = styled.div`
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
`;

const LightContent = styled.div`
  color: ${({ theme }) => theme.textLighter};
`;

const WrapperInfos = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  div:nth-child(2n + 1) {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const HotelName = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const HotelAdress = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const BookingSummary = ({
  hotel,
  startDate,
  endDate,
	selectedPlan,
}) => {
  const { t } = useTranslation();
  const hotelName = hotel.displayName;
  const hotelAddress = hotel.address;
  const img = get(hotel, 'primaryAttachment.smallUrl');

  return (
    <BookingInfo>
      <Grid>
        <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
          {
            Boolean(img)? <HotelPic image={ img} alt="" /> : <HotelPic image={placeholderHotel} alt="" />
          }
          <MdCheck  size={50} color='#fff' style={{marginLeft:'calc(50% - 25px)',marginTop:'-25px', backgroundColor:'#67CB8B', padding:'15px', borderRadius:'30px'}}/>
        </Cell>

        <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
          <HotelName>{hotelName}</HotelName>
          <HotelAdress>{hotelAddress}</HotelAdress>
          <WrapperInfos>
            <TitleCard>{t('hotel.dates.stay')}</TitleCard>
            <LightContent>{journeyFormatDate(
                startDate,
                )}  <MdTrendingFlat size={40} color={'#67CB8B'}/>  {journeyFormatDate(endDate)}
            </LightContent>
            <>
              <TitleCard>{t('hotel.room.title')}</TitleCard>
              <LightContent>
                {selectedPlan.plan && selectedPlan.plan.map(plan =>(
                  <p style={{color:'#6C6C6C'}}>{plan.roomDescription}</p>
                ))}
              </LightContent>
            </>
          </WrapperInfos>
        </Cell>
      </Grid>
    </BookingInfo>
  )
};

export default BookingSummary;
