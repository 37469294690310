import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { size } from 'ui';
import getMinutes from 'date-fns/get_minutes';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

const BookingInfo = styled.div`
  border-radius: 4px;
  margin-top:20px;
  font-size: ${size(0)};
`;

const Timer = styled.span`
  vertical-align: baseline;
  font-size: ${size(0)};
  ${props =>
    props.huge &&
    css`
      font-weight: 600;
      font-size: ${size(2)};
    `}
`;

const getTimeSeconds = date =>
  Math.floor((date ? new Date(date) : new Date()).getTime() / 1000);

const BookingCountdown = ({ expiryDate }) => {
  const [seconds, setSeconds] = useState(getTimeSeconds());

  const handleRefreshTime = useCallback(() => {
    const currentTime = getTimeSeconds();
    if (seconds !== currentTime) {
      setSeconds(currentTime);
    }
    requestAnimationFrame(handleRefreshTime);
  }, [seconds]);

  useEffect(() => {
    handleRefreshTime();
  }, [handleRefreshTime]);

  const secondsLeft = useMemo(
    () => Math.max(getTimeSeconds(expiryDate) - seconds, 0),
    [expiryDate, seconds],
  );

  const { t } = useTranslation();

  return (
    <BookingInfo>
      {t('profiles.account.optionsDeadlineWarning')}{' '}
      <Timer huge={secondsLeft < 300}>
        {secondsLeft >= 300
          ? `${getMinutes(secondsLeft * 1000)} minutes`
          : format(secondsLeft * 1000, 'mm:ss')}
      </Timer>
    </BookingInfo>
  );
};

export default BookingCountdown;
