import React from 'react';
import Modal from '../../components/Modal';
import { BookingPolicies } from './BookingPolicies';

const ConsentModal = ({ isOpen, onClose, koediaRateDetails, startDate }) => {
  return (
      <Modal isOpen={isOpen} onRequestClose={onClose} scrollable>
        {koediaRateDetails && (
          <BookingPolicies koediaRateDetails={koediaRateDetails} startDate={startDate}>

          </BookingPolicies>
        )}
      </Modal>
  );
};

export default ConsentModal;
