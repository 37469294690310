import React, {useContext} from 'react';
import Button from './UI/Button';
import { useTranslation } from 'react-i18next';
import CountryDescriptionInformations from './CountryDescriptionInformations';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContext from 'contexts/page';

export default function BookingValidationDestination({country}) {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);

  const CountryLink = styled(Link)`
  text-decoration: none;
  `;
  return (
    <div className="bg-gray-100 rounded-3xl p-10">
      <div className="text-2xl font-semibold text-center">{t('booking.details.countryInfos.title')}</div>

      <div className="my-10 flex justify-around">
        <CountryDescriptionInformations country={country} />
      </div>

      <div className="flex justify-center">
      <CountryLink to={getLanguagePrefix(country?.slug)}>
        <Button>{t('booking.details.countryInfos.button')}</Button>
      </CountryLink>
      </div>
    </div>
  )
}
