import React from 'react';
import { Router, Match, Redirect } from '@reach/router';
import { useTranslation } from 'react-i18next';
import SEO from 'components/seo';
import Booking from 'containers/Booking';
import { useDataLayerBuilder } from '@tymate/react-gtm';

const RedirectWithQuery = ({ hotelId, location }) => {
  return <Redirect noThrow to={`/booking/${hotelId}/rooms-composition`} />;
};

const BookingPage = () => {
  const { t } = useTranslation();
  useDataLayerBuilder(() => ({
    pageType: 'booking',
  }));

  return (
    <>
      <SEO title={t('pageTitle.booking')} />
      <Match path="/booking/:hotelId/:step">
        {({ match }) => (
          <Router>
            <Booking path="/booking/:hotelId/*" match={match} />
            <RedirectWithQuery path="/booking/:hotelId" />
          </Router>
        )}
      </Match>
    </>
  );
};

export default BookingPage;
