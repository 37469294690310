import React from 'react';
import { Stack } from '@tymate/margaret';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { calculatePromotionalCode } from 'api/bookings';
import { Button } from 'ui';
import { ErrorText } from 'components/Forms';
import { useTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import Input from '../../components/UI/Input';
import { ERRORS } from '../../utils';

const BookingPromoCode = ({
  bookingId,
  hasDiscountCode,
  setHasDiscountCode,
  setDiscountPrice,
  setPromoAmount,
  setPromoCode,
  disableButtons
}) => {
  const { t } = useTranslation();

  const handleSubmit = async (values, { setStatus }) => {
    try {
      const response = await calculatePromotionalCode({ ...values, bookingId });
      setDiscountPrice((response?.booking?.discountedPrice / 100).toFixed(2));
      setPromoAmount((response?.booking?.promoAmount / 100).toFixed(2));
      setPromoCode({promoCode:values.promotionalCode});
      setHasDiscountCode(true);
      setStatus();
    } catch (e) {
      setStatus(t('booking.promotional.invalidCode'));
    }
  };
  return (
    <Stack direction="column" alignY="flex-end">
      <div className="w-full xl:flex xl:justify-between">
        <Formik
          enableReinitialize
          initialValues={{ promotionalCode: '' }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            promotionalCode: Yup.string().required(ERRORS.REQUIRED),
          })}
        >
          {({ isSubmitting, values, status }) => {
            return (
              <Form>
                <div className="mb-8 grid grid-cols-3 gap-2">
                  <div className="col-span-2">
                    <Input
                      label={t('booking.promotional.promotionalCode')}
                      name="promotionalCode"
                      type="text"
                      placeholder={t('booking.promotional.placeholder')}
                      fieldClass="bg-transparent border-brand-text"
                    />
                    {Boolean(status) && Boolean(values.promotionalCode) && (
                      <ErrorText>{status}</ErrorText>
                    )}
                  </div>

                  <Button
                    variant="promocode"
                    type="submit"
                    disabled={isSubmitting || disableButtons}
                  >
                    {Boolean(isSubmitting) ? (
                      <MDSpinner size={18} singleColor="#fff" />
                    ) : (
                      t('utils.validate')
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <div className="mb-10 xl:mb-0">
          <Button
            type="submit"
            variant="promocode"
            isActive={hasDiscountCode === true}
            onClick={() => {
              setHasDiscountCode(false);
            }}
            disabled={hasDiscountCode !== null}
            className="mx-auto xl:mx-0"
          >
            {t('booking.promotional.noPromoCode')}
          </Button>
        </div>
      </div>
    </Stack>
  );
};

export default BookingPromoCode;
