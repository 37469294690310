import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { get,round } from 'lodash';
import { Stack } from '@tymate/margaret';
import { MdTrendingFlat } from 'react-icons/md';
import { Button, Buttons, Grid, Cell, media } from 'ui';
import { journeyFormatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import BookingContext from 'contexts/booking';
import paygreenSecurity from '../../assets/images/paygreenSecurity.png';
import placeholderHotel from '../../assets/images/placeholderHotel.png';
import CheckboxField from 'components/Checkbox';
import ConsentModal from 'containers/Booking/ConsentModal';
import BookingCountdown from 'containers/Booking/BookingCountdown';

const NewButtons = styled(Buttons)`
  margin-top:10px;
`;
const HotelPic = styled.div`
  background-image: url("${props => props.image}");
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 145px;
`;
const BookingContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  ${media.desktop`
    width: unset;
  `}
`;
const BookingInfo = styled.div`
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 30px;
  padding: ${({ theme }) => theme.spacing()};

`;

const Conditions = styled.div`
  margin-bottom: 0.6rem;
  color: #d11e1f;
`;

const TitleCard = styled.div`
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
`;

const NormalContent = styled.div`
  margin-top:0px !important;
`;

const LightContent = styled.div`
  color: ${({ theme }) => theme.textLighter};
  margin-top:0px !important;
  flex:1;
`;

const WrapperInfos = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  div:nth-child(2n + 1) {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const Image = styled.img`
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(-0.5)};
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const CenterContainer = styled.div`
  display:flex;
  justify-content:center;
  flex-direction:column;
`;



const HotelName = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing()};
`;

const HotelAdress = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const WrapperPrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
`;

const TotalPrice = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`;

const LinkCond = styled.span`
  font-weight: 800;
  border-bottom: 1px solid #000;
  text-transform: lowercase;
`

const BookingAmount = styled.div`
  color: #67CB8B;
  font-weight:800;
  font-size: 30px;
  ${props => props.barre && css`
    text-decoration: line-through;
  `}
`;

const HotelBookedSummary = ({
  hotel,
  startDate,
  endDate,
  rooms,
  totalRoomNumber,
  disabledButton,
  step,
  isConditions,
  isInfo,
  originalPrice,
  discountPrice,
  promoAmount,
  selectedPlan,
  currentSelectedRoom,
  handleNextStep = () => {},
  expiryDate
}) => {
  const { koediaRateDetails } = useContext(BookingContext);
  const { t } = useTranslation();
  const hotelName = hotel.displayName;
  const hotelAddress = hotel.address;
  const img = get(hotel, 'primaryAttachment.smallUrl');
  const originalPriceToPay =
    Boolean(originalPrice) &&
    typeof originalPrice == 'string' &&
    !isNaN(originalPrice)
      ? originalPrice
      : koediaRateDetails?.rateInformation?.totalPrice?.['@rack'];

  const getButtonLabel = () => {
    switch(step) {
      case 1:
        return t('utils.consultPrices');
      case 2:
        return ((rooms.length === (totalRoomNumber - 1)) && currentSelectedRoom ? t('utils.finalize') : t('utils.continueSelection'));
      case 4:
        return t('utils.paywithcard')
      default:
        return t('utils.payer');
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  let originalTotalPrice = originalPriceToPay;

  if (!originalPrice && ((rooms && rooms.length>0) || currentSelectedRoom)) {
    originalPrice = 0;
    if (currentSelectedRoom) {
      originalPrice+=parseFloat(currentSelectedRoom['roomPrice']['total']['@rack']);
    }
    if (rooms) {
      rooms.forEach((room) => {
        originalPrice+=parseFloat(room['roomPrice']['total']['@rack']);
      });
    }
    originalTotalPrice=originalPrice;
  }

  return (
    <BookingContainer>
      <BookingInfo>
        <Grid>
          <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
            {
              Boolean(img)? <HotelPic image={ img} alt="" /> : <HotelPic image={placeholderHotel} alt="" />
            }

          </Cell>
          <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
            <HotelName>{hotelName}</HotelName>
            <HotelAdress>{hotelAddress}</HotelAdress>
          </Cell>
        </Grid>
        <WrapperInfos>
          <TitleCard>{t('hotel.dates.stay')}</TitleCard>
          <NormalContent>{journeyFormatDate(
            startDate,
            )}  <MdTrendingFlat size={40} color={'#67CB8B'}/>  {journeyFormatDate(endDate)}

          </NormalContent>
            {((rooms && rooms.length>0) || currentSelectedRoom) && (
              <TitleCard>{t('hotel.room.title')}</TitleCard>
            )}
            {rooms && (
              <>
              {
                rooms.map(room => (
                  <WrapperPrice style={{ marginTop: 0,flex: 'content' }}>
                    <LightContent>
                      {room.roomDescription}
                    </LightContent>
                    <TotalPrice className="w-fit">{`${round(room['roomPrice']['perDay']['@rack'], 2)}€`}</TotalPrice>
                  </WrapperPrice>
                  ))
                }
                { currentSelectedRoom && (
                  <WrapperPrice style={{ marginTop: 0,flex: 'content'}}>
                    <LightContent>
                      {currentSelectedRoom.roomDescription}
                    </LightContent>
                    <TotalPrice className="w-fit">{`${round(currentSelectedRoom['roomPrice']['perDay']['@rack'], 2)}€`}</TotalPrice>
                  </WrapperPrice>
                  )
                }
            </>
          )}

          {selectedPlan && (
              <>
              {
                selectedPlan.plan.map(room => (
                  <WrapperPrice style={{ marginTop: 0,flex: 'content' }}>
                    <LightContent>
                      {room.roomDescription}
                    </LightContent>
                    <TotalPrice className="w-fit">{`${round(room['roomPrice']['perDay']['@rack'], 2)}€`}</TotalPrice>
                  </WrapperPrice>
                  ))
                }
            </>
          )}
          <Stack direction="column" style={{ marginTop: '20px' }}>
            <div style={{ width: '100%', marginTop: 0 }}>
              {!Boolean(discountPrice) ? (
                Boolean(originalTotalPrice) && (
                  <WrapperPrice style={{ marginTop: 0 }}>
                    <TotalPrice>{`${t(
                      'booking.promotional.total',
                    )} :`}</TotalPrice>
                    <BookingAmount>{`${Number(originalTotalPrice).toFixed(2)} €`}</BookingAmount>
                  </WrapperPrice>
                )
              ) : (
                <div>
                  <WrapperPrice style={{ marginTop: 0 }}>
                    <TotalPrice>{`${t(
                      'booking.promotional.total',
                    )} :`}</TotalPrice>
                    <BookingAmount barre>
                      {`${Number(originalTotalPrice).toFixed(2)} €`}
                    </BookingAmount>
                  </WrapperPrice>
                  <WrapperPrice>
                    <div>{`${t('booking.promotional.discount')} :`}</div>
                    <BookingAmount>{`- ${promoAmount} €`}</BookingAmount>
                  </WrapperPrice>
                  <WrapperPrice style={{ marginTop: 0 }}>
                    <div>{`${t('booking.promotional.total')} :`}</div>
                    <BookingAmount>{`${Number(discountPrice).toFixed(2)} €`}</BookingAmount>
                  </WrapperPrice>
                </div>
              )}
            </div>
          </Stack>
        </WrapperInfos>
        { isInfo ?
        <>
        <CheckboxField
              inCardPay
              name="policiesConsent"
              label={t('booking.info.infocheck')}
          />
          <LinkCond onClick={() => setIsOpen(true)}>{t('booking.info.infocheckcond')}</LinkCond>
          </>

          : ''
        }
        {!isConditions && isInfo ? (
          <Conditions>{t('booking.details.conditions')}</Conditions>
        ) : null}
        <NewButtons>
          <Button
            variant="primary"
            full
            reservation
            disabled={disabledButton}
            onClick={handleNextStep}
          >
            {getButtonLabel()}
          </Button>
        </NewButtons>
      </BookingInfo>
      <CenterContainer>
        <Image src={paygreenSecurity} alt="" />
        { expiryDate && <BookingCountdown expiryDate={expiryDate}></BookingCountdown>}
      </CenterContainer>
      <ConsentModal isOpen={isOpen} onClose={onClose} koediaRateDetails={koediaRateDetails} startDate={startDate}/>

    </BookingContainer>
  );
};

export default HotelBookedSummary;
