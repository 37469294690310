import subDays from "date-fns/sub_days";
import { get, round } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getItemAsArray } from 'utils';
import {
  journeyFormatDate,
  parseBookingDate
} from 'utils/date';
import CheckboxField from 'components/Checkbox';
const NewCheckboxField = styled(CheckboxField)`

`;

const Policies = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;

  padding: ${({ theme }) => theme.spacing()};
  margin-right: ${({ theme }) => theme.spacing()};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const PoliciesRemarks = styled.div`
  font-size: 14px;
  opacity: 0.8;
`;

const RoomTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #67CB8B;
  ${props =>
  props.condition &&
  css`
      color: #000;
    `}
`;

const RoomsLine = styled.div`
  position: relative;
  display: block;
  border: 1.5px solid rgba(0, 0, 0, 0.12);
  outline: none;
  background-color: #fff;
  margin-top: 50px;
  border-radius: 30px;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing()};

  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

 li {
   list-style:none;
   font-weight: 700;
   margin-left: -40px;
  }
`;


const OptionWrapper = styled.div`
  position: absolute;
  height: 36px;
  width: 124px;
  background-color: rgba(0, 0, 0, 0.06);
  top: -15px;
  color: rgba(0, 0, 0, 0.5) !important;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(0.35)}
            ${({ theme }) => theme.spacing(0.3)};
  ${props =>
  props.greenOption &&
  css`
      background-color: #EEF8F2;
      color: ${({ theme }) => theme.primary} !important;
    `}

  ${props =>
  props.orangeOption &&
  css`
      background-color: #FFE3E3;
      color: #EA5D5D !important;
      width: 154px;
    `}
`;

export const BookingPolicies = ({ koediaRateDetails, startDate }) => {

  const { t } = useTranslation();

  const {
    contractRemarks,
    rateInformation = {},
    cancellationFeesPolicyByItemUnits,
  } = koediaRateDetails || {};
  const remarks = getItemAsArray(get(contractRemarks, 'contractRemark'));

  const cancellationPolicies =
    getItemAsArray(cancellationFeesPolicyByItemUnits?.itemUnit ?? {}) || [];

  const checkinDate = parseBookingDate(startDate);

  return (
    <Policies>
      <RoomTitle condition>
        {t('booking.details.summary.conditions')}
      </RoomTitle>
      {(cancellationPolicies ?? []).map(item => {
        const cancellationFeesPolicy =
          item?.cancellationFeesPolicy ||
          koediaRateDetails?.cancellationFeesPolicy;
        let cancellationFeesPolicyArray = Array.isArray(cancellationFeesPolicy) ? cancellationFeesPolicy : [cancellationFeesPolicy];
        return (
          <RoomsLine>
            {rateInformation.refundable ?
              (
                <OptionWrapper greenOption>
                  {t('booking.details.refundable')}
                </OptionWrapper>
              ) : (
                <OptionWrapper orangeOption>
                  {t('booking.details.nonRefundable')}
                </OptionWrapper>
              )
            }
            <ul>
              <li>
                <p>{item?.itemUnitDescription ?? ''}</p>
                {rateInformation.refundable &&
                  <ul>
                  {
                    cancellationFeesPolicyArray.map((cancellationFeesPolicyObject) => {
                      const {
                        fromDay,
                        toDay,
                        amount,
                      } = cancellationFeesPolicyObject;
                      return (
                        <li>
                          {rateInformation.refundable && <PoliciesRemarks>
                            {t('profiles.booking.cancel2')}{' '}
                            {journeyFormatDate(
                              subDays(checkinDate, Number(toDay)),
                            )}{' '}
                            {`au`}{' '}
                            {journeyFormatDate(
                              subDays(checkinDate, Number(fromDay)),
                            )}{' '}
                            {t('profiles.booking.cancel3')}{' '}
                            {round(get(amount, '@rack'), 2)} €
                          </PoliciesRemarks>
                          }
                        </li>
                      )
                    })
                  }
                  </ul>
                }
              </li>
            </ul>
          </RoomsLine>
        );
      })}
      <PoliciesRemarks>
        {(remarks || []).map(remark => (
          <p>{remark}</p>
        ))}
      </PoliciesRemarks>
      <NewCheckboxField
        name="policiesConsent"
        label={t('booking.info.policiesConsent')}
      />
    </Policies>
  )
}
