import React, { useContext } from 'react';
import styled from 'styled-components';
import { get , find} from 'lodash';
import BookingContext from '../../contexts/booking';
import { Container } from '../../ui/containers';
import BookingSummary from './BookingSummary';
import { useTranslation } from 'react-i18next';
import TicTacTripWidget from '../../components/TicTacTripWidget';
import BookingValidationDestination from '../../components/BookingValidationDestination';

const Title = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #67CB8B;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const BookingValidation = ({ state }) => {
  const {
    hotel,
    startDate,
    endDate,
    booking,
    koediaPlans,
    selectedPlanId
  } = useContext(BookingContext);

  const { t } = useTranslation();
  const selectedPlan = find(koediaPlans, { id: selectedPlanId }) || {};

  return (
    <Container reservation>
      <Title>{t('utils.reserved')}</Title>
      <div className="max-w-7xl mx-auto">
        <div className="grid xl:grid-cols-3 gap-5">
          <div className="xl:col-span-2 flex flex-col space-y-5">
            <BookingSummary
              startDate={startDate}
              endDate={endDate}
              isInfo={false}
              nbRooms={get(booking, 'rooms', []).length}
              hotel={hotel}
              selectedPlan={selectedPlan}
              isValidated={true}
            />

            <div className="">
              <BookingValidationDestination
                country = {hotel.city.country}
              />
            </div>
          </div>

          <div>
            <TicTacTripWidget/>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BookingValidation;
