import styled, { css } from 'styled-components';
import { get, round } from 'lodash';
import { useTranslation } from 'react-i18next';
import EditReservationIcon from '../../assets/images/EditReservationIcon.svg';
import React from 'react';
import { media, TextButton} from 'ui';

const ChangedButton = styled(TextButton)`
  width:105px !important;
  height:27px;
`;

const FlexContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
`;

const FlexContent = styled.div`
  flex: fit-content;
`;

const ColumnAlignRight = styled.div`
  display:flex;
  flex-direction:column;
  align-items:end;
`;

const RoomDetails = styled.span`
  color: ${({ theme }) => theme.textLighter};
  margin-right:5px;
`;

const PictureAndDetails = styled.div`
  width: 100%;
  display: flex;
  padding-left: 0;
  padding-right: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing()};
  `}
  p {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
  
`;

const RoomTittle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin: ${({ theme }) => theme.spacing(0)};
`;

const RoomPrice = styled.span`
  font-size: 16px;
  text-align:right;
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `
  }
  ${props =>
    props.big  &&
    css`
        color: #67CB8B;
        font-size:21px;
    `
  }
  
  ${props =>
    props.marginRight  &&
    css`
          margin-right:5px;
      `
  }
  
  
`;

export const RoomCard = ({ room,roomIndex, possibility, deleteRoom, selected }) => {
  const currency = get(possibility, '@currency', '');
  const { t } = useTranslation();

  return (
      <PictureAndDetails>
        {selected ?
          <FlexContainer full>
            <FlexContent>
              <RoomTittle>{get(room, 'roomDescription', '')}</RoomTittle>
            </FlexContent>
            <FlexContent>
              <ColumnAlignRight>
                <ChangedButton
                  type="button"
                  onClick={() => deleteRoom(roomIndex)}
                >
                  {t('hotel.room.update')}
                  <img src={EditReservationIcon} alt=""  style={{ marginLeft: '8px', height: '15px', width:'15px'}} />

                </ChangedButton>
              </ColumnAlignRight>
            </FlexContent>
          </FlexContainer>
          :
          <FlexContainer full>
            <FlexContent>
              <RoomTittle>{get(room, 'roomDescription', '')}</RoomTittle>
              <p>
                {get(room, 'nbAdults', '') !== '0' && (
                  <RoomDetails>
                    {`${t('hotel.room.adults',{count:Number(get(room, 'nbAdults',0))})}`}
                  </RoomDetails>
                )}
                {get(room, 'nbChildren', '') !== '0' && (
                  <RoomDetails>
                    {`${t('hotel.room.andChildren',{count:Number(get(room, 'nbChildren',0))})}`}
                  </RoomDetails>
                )}
                {Number(get(room, 'nbCot',0)) !== 0 && (
                  <RoomDetails>
                    {`${t('hotel.room.andBaby',{count:Number(get(room, 'nbCot',0))})}`}
                  </RoomDetails>
                )}
              </p>
            </FlexContent>
            <FlexContent>
              <ColumnAlignRight>
                <RoomPrice>
                  {`${t('hotel.room.pricePerDay',{currency,amount:round(get(room, 'roomPrice.perDay.@rack',0),2)})}`}
                </RoomPrice>
                <RoomPrice bold>
                  <RoomPrice bold big marginRight>
                    {`${t('hotel.room.priceTotal',{currency,amount:round(get(room, 'roomPrice.total.@rack',0),2)})}`}
                  </RoomPrice>
                  {`${t('hotel.room.total')}`}
                </RoomPrice>
              </ColumnAlignRight>
            </FlexContent>
          </FlexContainer>
        }
      </PictureAndDetails>
  );
};
