import React, { useEffect } from 'react';

const TicTacTripWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://widgetsearch.tictactrip.eu/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="ttt-iframe-wrapper flex justify-center">
      <a
        href="https://www.tictactrip.eu"
        data-iframe-src="https://widgetsearch.tictactrip.eu"
        data-iframe-width="100%"
        id="ttt-widget"
        data-iframe-lang="fr"
        data-iframe-cstmbg="https://www.tictactrip.eu/react-app/illusHome.png"
        data-iframe-blackfilter="0.3"
        data-iframe-header="true"
        data-iframe-headerlogo="false"
        data-iframe-title="Votre trajet en 2 clics"
        data-iframe-customtitle="true"
        data-iframe-headercolor="black"
        data-iframe-presetorigin=""
        data-iframe-presetdestination=""
        data-iframe-lockorigin="false"
        data-iframe-lockdestination="false"
        data-iframe-oneway="false"
        data-iframe-customcolor="22E4AA"
        data-iframe-bottomlogo="true"
        data-iframe-newpartnerid="FR-OT-FEELINGO"
      >
        .
      </a>
    </div>
  )
}

export default TicTacTripWidget;
